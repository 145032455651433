<template>
  <div class="sign-in-main">
    <div class="main-body">
      <div class="login-form">
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="login-detail">
              <form @submit.prevent="onSubmit">
                <div class="heading-login">
                  <h3>Change Password</h3>
                </div>
                <div class="form-group">
                  <label>Old Password</label>
                  <input type="password" v-model="user.oldPassword" name="password" class="form-control"
                    placeholder="Enter your password" id="oldPassword" :class="{
                'is-invalid': submitted && $v.user.oldPassword.$error,
              }" />
                  <div v-if="submitted && $v.user.oldPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.user.oldPassword.required">Please enter a valid old Password</span>
                  </div>
                </div>
                <div class="form-group">
                  <label>New Password</label>
                  <input type="password" v-model="user.newPassword" name="password" class="form-control"
                    placeholder="Enter your Password" id="newPassword" :class="{
                'is-invalid': submitted && $v.user.newPassword.$error,
              }" />
                  <div v-if="submitted && $v.user.newPassword.$error" class="invalid-feedback">
                    <span v-if="!$v.user.newPassword.required">Please enter a new password</span>
                    <span v-else-if="!$v.user.newPassword.passwordComplexity">Your password should be 8-70 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character</span>
                  </div>
                </div>
                <div class="form-group">
                  <label>Confirm New Password</label>
                  <input type="password" v-model="user.confirmPassword" name="password" class="form-control"
                    placeholder="Enter your password" id="confirmPassword" :class="{
                'is-invalid': submitted && $v.user.confirmPassword.$error,
              }" />
                  <div v-if="submitted && $v.user.confirmPassword.$invalid" class="invalid-feedback">
                    <span v-if="!$v.user.confirmPassword.required">Please enter a confirm new password</span>
                    <span v-else-if="!$v.user.confirmPassword.sameAsPassword">Password do not match</span>
                  </div>
                </div>
                <div class="text-center sign-btn mt-4">
                  <button type="submit" class="btn secondary-btn btn-block">
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6 px-0 desktop-screen bg-shape">
            <div class="right-bar">
              <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd">
                <!-- Slides with custom text -->
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Lorem ipsum</h3>
                    <p class="font-lg-14">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam con.
                    </p>
                    <button type="button" class="secondary-btn-outline mt-4">
                      Learn More
                    </button>
                  </div>
                </b-carousel-slide>
                <b-carousel-slide img-blank img-alt="Blank image">
                  <div class="slider-main">
                    <h3>Lorem ipsum</h3>
                    <p class="font-lg-14">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit. Exercitation veniam con.
                    </p>
                    <button type="button" class="secondary-btn-outline mt-4">
                      Learn More
                    </button>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CHANGE_PASSWORD } from "../../store/constant/actions.type";
import { required, sameAs, helpers } from "vuelidate/lib/validators";
import { loadingService } from "../../main/common/services/loading.service";
//---Password validation
const passwordComplexity = helpers.regex(
  'passwordComplexity',
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#()\-=+{}[\]|:;<>,.?/~`])[A-Za-z\d@$!%*?&^#()\-=+{}[\]|:;<>,.?/~`]{8,70}$/
)

export default {
  name: "forgotPassword",
  data() {
    return {
      user: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      submitted: false,
      slide: 0,
      fullPage: false,
    };
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },
  validations: {
    user: {
      oldPassword: { required },
      newPassword: { required, passwordComplexity },
      confirmPassword: { required, sameAsPassword: sameAs("newPassword") },
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    onSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let newPassword = this.user.newPassword;
      let oldPassword = this.user.oldPassword;
      loadingService.setloader(true);
      this.$store
        .dispatch(CHANGE_PASSWORD, {
          oldPassword,
          newPassword,
        })
        .then((response) => {
          if (response.success) {
            loadingService.setloader(false);
            this.flashMessage.success({
              title: "Success",
              message: "SuccessFully Password Changed",
            });
            this.email = null;
            this.newPassword = null;
            this.oldPassword = null;
            this.$router.push({ name: "login" });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.message,
            });
          }
        });
    },
    close() {
      this.$router.push({ name: "user" });
    },
  },
};
</script>
<style scoped>
.sign-in-header {
  display: flex;
  justify-content: space-between;
  padding: 15.5px 32px;
}

.heading-login h3 {
  padding-bottom: 16px;
}

.main-body {
  background: #e5e5e5;
  background-repeat: no-repeat;
  height: calc(100vh - 74.8px);
  overflow: auto;
  padding: 64px 15px 15px 15px;
}

/* 
.logo img {
  max-width: 50px;
} */

.login-form {
  height: 616px;
  color: white;
  overflow: hidden;
  max-width: 1126px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  position: relative;
}

.login-detail {
  background-color: #fff;
  width: 100%;
  z-index: 2;
  height: 616px;
  position: relative;
  padding: 0 15px;
}

.login-detail form {
  max-width: 307px;
  margin: 0 auto;
  padding-top: 72px;
}

.right-bar {
  position: relative;
  display: grid;
  place-content: center;
  height: 100%;
  z-index: 1;
}

.right-bar:before {
  background-color: rgb(255 255 255 / 12%);
  position: absolute;
  width: 200px;
  height: 200px;
  left: 306px;
  top: 102px;
  border-radius: 50%;
  z-index: -1;
  content: "";
}

.carousel {
  width: 480px;
  min-height: 500px;
  height: auto;
}

.bg-shape {
  position: relative;
  background-color: #003172;
}

.bg-shape:before {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 0;
  left: -13px;
  content: "";
  position: absolute;
  bottom: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-image: url("../../../assets/images/icons/login-bg.png");
}

.slider-main {
  width: 339px;
  background: #fff;
  padding: 49px 32px;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  border-radius: 4px;
  text-align: initial;
}

@media only screen and (max-width: 769px) {
  .slider-main {
    width: 250px;
    padding: 29px 20px;
  }

  .carousel {
    width: 370px;
    min-height: 270px;
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .main-body {
    padding: 34px 15px 15px 15px;
  }

  .sign-in-header {
    padding: 15.5px 15px;
  }

  .login-form {
    height: 566px;
  }
}
</style>
